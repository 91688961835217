<template>
  <header class="flex min-h-screen flex-col-reverse lg:flex-row">
    <Container
      class="flex flex-1 flex-col items-start pt-6 sm:pt-8 lg:pt-32"
      indent="wide"
    >
      <Breadcrumbs class="mb-10 pt-2 sm:mb-6 md:pt-4 lg:pt-14" />

      <h1
        v-if="page.title"
        class="mb-6 text-3xl font-medium md:text-6xl lg:mb-10 lg:max-w-[28.75rem] lg:text-5xl"
        v-html="$replaceHtmlTags(page.title)"
      />

      <Bard :data="page.text" />

      <Form
        key="form"
        ref="form"
        v-slot="{ errors }"
        novalidate
        class="w-full"
        @submit="onSubmit"
      >
        <InputField
          :field="{
            label: 'E-Mail',
            handle: 'email',
            type: 'text',
          }"
          :value="getEmailFromRouteQuery"
          label="E-Mail"
          rules="required|email"
          class="mt-6 w-full lg:mt-10"
          :error="errors['email']"
        />

        <div v-if="sent || error">
          <AlertBanner class="col-span-12 mt-4" :type="state">
            <p class="mt-0.5 text-sm font-medium md:text-base">
              {{ $t('magic_link_sent') }}
            </p>

            <template #error>
              <p class="mt-0.5 text-sm font-medium md:text-base">
                <template v-if="error?.status === 404">
                  {{ $t('form_email_not_found_error') }}
                </template>
                <template
                  v-else-if="error?._data?.message === 'User not confirmed.'"
                >
                  {{ $t('form_user_not_confirmed_error') }}
                </template>
                <template v-else>
                  {{ $t(error?._data?.message || error) }}
                </template>
              </p>
            </template>
          </AlertBanner>
        </div>

        <AppButton
          v-if="state !== 'success'"
          :is-loading="state === 'loading'"
          class="mt-11"
        >
          {{ $t('request_login_link') }}
        </AppButton>
      </Form>

      <Bard :data="page.remark_text" class="mt-28" />
    </Container>

    <div class="w-full lg:w-1/2 xl:w-[55%]">
      <AppImage
        v-if="page.image"
        :alt="$i18n.locale === 'fr' ? page.image.alt_fr : page.image.alt"
        class="h-[40vh] w-full object-cover lg:h-full"
        :src="page.image"
        :style="`object-position: ${page.image.focus_css};`"
      />
    </div>
  </header>
</template>

<script setup>
import { Form } from 'vee-validate'

defineProps({
  page: { type: Object, required: true },
})

const route = useRoute()
const { setIsWhite } = useNavigationStore()
const getEmailFromRouteQuery = computed(() => route.query.email || null)
const state = ref('idle')
const sent = ref(false)
const error = ref(null)
const { locale } = useI18n()

// This query param is set if the magic link is invalid (redirect to '/login?error=invalid_token')
if (route.query.error) {
  error.value = route.query.error
  state.value = 'error'
}

onMounted(async () => {
  setIsWhite(true)
})

const onSubmit = async (data, { resetForm }) => {
  sent.value = false

  if (route.query.benchmark) {
    data.benchmark = true
  }

  if (route.query.returnUrl) {
    data.returnUrl = route.query.returnUrl
  }

  try {
    state.value = 'loading'

    await api(
      '/login',
      {
        method: 'POST',
        body: data,
      },
      locale.value
    )

    state.value = 'success'

    resetForm()
  } catch (e) {
    state.value = 'error'
    error.value = e.response
  } finally {
    sent.value = true
  }
}
</script>
